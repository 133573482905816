* {
    font-size: 12px;
    font-family: 'Times New Roman';
    text-transform: uppercase;
}
td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
    text-align: left;
}

td.description,
th.description {
    width: 150px;
    max-width: 150px;
}

td.quantity,
th.quantity {
    width: 50px;
    max-width: 50px;
    word-break: break-all;
}
.no-border-tr {
    border-top: 2px solid transparent;

}

.bold-text {
    font-weight: bold;
}
td.price,
th.price {
    width: 50px;
    max-width: 50px;
    word-break: break-all;
}

.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 250px;
    max-width: 250px;
}

img {
    max-width: inherit;
    width: inherit;
}

@media print {

    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}