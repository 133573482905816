:root {
  --font-color: #000;
  --background-color: #eee;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden;
}
input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 10px;
}
h5 {
  font-size: 1.2rem;
  margin: 4px 0px 4px 0px;
}
h6 {
  font-size: 1rem;
  margin: 4px 0px 4px 0px;
}
p {
  font-size: 0.9rem;
  margin: 2px 0px 2px 0px;
}

.main-content {
  width: 100vw;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.center {
  justify-content: center;
}
.between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 40%;
}
.loader1 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;
}
.card {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
}

@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.details {
  width: 90vw;
  animation: append-animate 0.3s forwards;
  margin-top: 30px;
}
.submit {
  max-width: 90vw;
  margin-top: 30px;
}
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 1vw 2.5vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
}
.submit > button {
  background-color: #000;
  color: var(--background-color);
  margin: auto;
  display: flex;
  margin-top: 20px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  width: 92vw;
  justify-content: space-evenly;
}
.list-item {
  width: 16vw;
  margin-top: 20px;
}
.list-item > p {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-item > h6 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sort {
  margin-top: 30px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 1000px) {
  .main-content {
    width: 100vw;
  }
  .submit {
    margin-top: 10px;
    width: 85vw;
  }
  .details {
    width: 85vw;
  }
  .list {
    width: 87vw;
  }
  .list-item {
    width: 23vw;
  }
  .header {
    flex-direction: column;
  }
  .button {
    padding: 1vw 15vw;
  }
}

@media (max-width: 550px) {
  .list-item {
    width: 35vw;
  }
  .button {
    padding: 1vw 7vw;
  }
}
@media (max-width: 350px) {
  .list-item {
    width: 85vw;
  }
}
.ml-20{
  margin-left: 20px;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.top-2{
  margin-top: 5px;
}